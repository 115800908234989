import { useEffect, useRef, useState } from 'react';
import config from '~/config';

const useFingerprint = (): {
	getVisitorId: () => Promise<{ requestId: string; visitorId: string }>;
	isFetching: boolean;
	isInitialised: boolean;
} => {
	const [isInitialised, setIsInitialised] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const fingerPrintRef = useRef<{
		get: () => Promise<{ requestId: string; visitorId: string }>;
	}>(null);

	useEffect(() => {
		const loadFP = async () => {
			(fingerPrintRef as any).current = await import(
				/* @vite-ignore */
				`https://fp.kopromos.com.au/web/v3/${config.fingerprintApiKey}`
			).then(FingerprintJS =>
				FingerprintJS.load({
					endpoint: [
						'https://fp.kopromos.com.au',
						// Fallback endpoint
						FingerprintJS.defaultEndpoint,
					],
				}),
			);
			setIsInitialised(true);
		};

		if (config.useFingerprint) {
			loadFP();
		}
	}, []);

	const getVisitorId = async () => {
		if (!isInitialised || !fingerPrintRef.current || !config.useFingerprint) {
			return {
				visitorId: '',
				requestId: '',
			};
		}

		const visitorIdFromStorage = sessionStorage.getItem('ko.visitorId');

		if (visitorIdFromStorage) {
			return {
				visitorId: visitorIdFromStorage,
				requestId: sessionStorage.getItem('ko.requestId') || '',
			};
		}

		try {
			setIsFetching(true);

			const response = await fingerPrintRef.current.get();

			sessionStorage.setItem('ko.visitorId', response.visitorId);
			sessionStorage.setItem('ko.requestId', response.requestId);

			return {
				visitorId: response.visitorId,
				requestId: response.requestId,
			};
		} catch (e) {
			setIsFetching(false);
			return {
				visitorId: '',
				requestId: '',
			};
		}
	};

	return {
		isFetching,
		isInitialised,
		getVisitorId,
	};
};

export default useFingerprint;
